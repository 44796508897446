<script lang="ts" setup>
const { contacts } = storeToRefs(useSiteStore());
</script>

<template>
  <div class="contacts-block">
    <a
      :href="`tel:${contacts.phone}`"
      :title="contacts.phone"
      class="font-bold"
    >
      {{ contacts.phone }}
    </a>

    <a
      :href="contacts.tgLink"
      class="flex justify-center items-center gap-2 text-xs"
      rel="nofollow noopener"
      target="_blank"
    >
      <SvgoDialog
        :font-controlled="false"
        class="h-3"
        filled
      />
      Задать вопрос в Telegram
    </a>
  </div>
</template>

<style lang="scss" scoped>
.contacts-block {
  // display will come from the parent component
  flex-direction: column;
  gap: 8px;
  align-items: center;

  @include screen-sm {
    align-items: flex-start;
  }
}
</style>
