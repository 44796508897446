<script lang="ts" setup>
const siteStore = useSiteStore();
const cartStore = useCartStore();
const authStore = useAuthStore();

const { cart } = storeToRefs(cartStore);

const cartBadge = computed(() => {
  let itemsInCart = 0;

  cart.value?.items.forEach((item) => {
    if (item?.count) itemsInCart += item.count;
  });

  return itemsInCart;
});

// TODO: Move to SSR after full site running
onMounted(async () => {
  if (!siteStore.aboveHeaderBlock.isActive) return;

  await nextTick();

  if (!sessionStorage.getItem('oldSiteLinkShow')) {
    sessionStorage.setItem('oldSiteLinkShow', 'true');
    siteStore.isShowAboveHeaderBlock = true;
  } else {
    const storedState = sessionStorage.getItem('oldSiteLinkShow');
    if (storedState) {
      siteStore.isShowAboveHeaderBlock = JSON.parse(storedState);
    }
  }
});
</script>

<template>
  <header class="header__container">
    <LineAboveHeader v-show="siteStore.isShowAboveHeaderBlock" />

    <div class="header__wrapper">
      <div class="header__content">
        <a
          :href="`tel:${siteStore.contacts.phone}`"
          :title="siteStore.contacts.phone"
          class="header__phone-icon"
        >
          <SvgoPhone
            :font-controlled="false"
            class="size-8"
            filled
          />
        </a>

        <AppLink
          class="header__logo"
          to="/"
        >
          <SvgoLogo
            :font-controlled="false"
            class="w-25 h-5"
          />
        </AppLink>

        <SearchBlock class="header__search-block" />

        <ContactsBlock class="header__contacts-block" />

        <div class="header__actions">
          <MenuActionBtn
            :counter-badge="cartBadge"
            :is-active="$route.path === '/cart'"
            label="Корзина"
            top-icon="Cart"
            url="/cart"
          />

          <MenuActionBtn
            :is-active="$route.path === '/likeable'"
            label="Избранное"
            top-icon="Favorites"
            url="/likeable"
          />

          <MenuActionBtn
            :is-active="authStore.isLogged ? $route.path === '/profile' : $route.path === '/login'"
            :label="authStore.isLogged ? 'Профиль' : 'Войти'"
            :top-icon="authStore.isLogged ? 'Profile' : 'Login'"
            :url="authStore.isLogged ? '/profile' : '/login'"
          />
        </div>

        <BurgerBtn
          :is-open="siteStore.isMobileMenuOpen"
          :title="siteStore.isMobileMenuOpen ? 'Закрыть меню' : 'Открыть меню'"
          class="header__burger-btn"
          @click="siteStore.toggleMobileMenu"
        />

        <DesktopMenu class="header__desktop-menu" />

        <MobileMenu
          :class="{ 'header__mobile-menu--open': siteStore.isMobileMenuOpen }"
          class="header__mobile-menu"
        />
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
.header {
  &__container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    display: flex;
    flex-direction: column;
    width: 100vw;
  }

  &__wrapper {
    @include inner-wrapper;

    position: relative;
    border-bottom: 1px solid $hell-black-20;
    background-color: $white;
  }

  &__content {
    @include inner-content;

    display: grid;
    grid-template-areas:
      'phone-icon logo burger'
      'search search search';
    grid-template-rows: auto auto;
    grid-template-columns: auto 1fr auto;
    gap: 0.5rem 2rem;
    align-items: center;
    padding: 0.5rem 1rem 0;

    @include screen-sm {
      grid-template-areas:
        'logo contacts burger'
        'search search search';
    }

    @include screen-md {
      grid-template-areas:
        'logo contacts actions'
        'search search search'
        'desktop-menu desktop-menu desktop-menu';
      grid-template-rows: auto auto auto;
      padding: 0.5rem 1.5rem 0;
    }

    @include screen-lg {
      grid-template-areas:
        'logo search contacts actions'
        'desktop-menu desktop-menu desktop-menu desktop-menu';
      grid-template-rows: auto auto;
      grid-template-columns: auto 1fr auto auto;
    }
  }

  &__phone-icon {
    @apply block sm:hidden;

    grid-area: phone-icon;
  }

  &__logo {
    @apply my-2 justify-self-center sm:justify-self-start;
    @apply hover:text-hell-black-80;

    grid-area: logo;
  }

  &__search-block {
    @apply mb-2.5 mt-0 md:mb-0 md:-mt-1.5;

    position: relative;
    grid-area: search;
    align-self: self-start;
    height: 50px;
  }

  &__contacts-block {
    display: none;
    grid-area: contacts;
    justify-self: end;

    @apply sm:flex;
  }

  &__actions {
    @apply hidden md:flex items-center justify-between gap-3 text-2xs;

    grid-area: actions;
  }

  &__burger-btn {
    @apply block md:hidden;

    grid-area: burger;
  }

  &__desktop-menu {
    @apply hidden md:flex;

    grid-area: desktop-menu;
  }

  &__mobile-menu {
    position: absolute;
    top: 113px;
    left: -300%;
    transition: left 0.3s;

    @include screen-md {
      display: none;
    }

    &--open {
      left: 0;
    }
  }
}
</style>
