<script lang="ts" setup>
import type { Ref } from 'vue';
import type { DesktopMenuItem } from '~/types/SiteSettings';

const desktopMenu: Ref<DesktopMenuItem[]> = ref([]);

const siteStore = useSiteStore();
desktopMenu.value = await siteStore.getDesktopMenu();

const hoveredItem: Ref<DesktopMenuItem | null> = ref(null);

const isDropDownOpen = computed(() => hoveredItem.value?.children?.length);

function closeDropDown() {
  hoveredItem.value = null;
}
</script>

<template>
  <nav @mouseleave="closeDropDown">
    <div class="menu-wrapper">
      <div class="menu">
        <AppLink
          v-for="item in desktopMenu"
          :key="item.slug"
          :to="makeUrlRelative(item.url)"
          class="menu__item"
          @mouseover="hoveredItem = item"
        >
          <img
            v-if="item.svg_url"
            :alt="item.name"
            :src="item.svg_url"
            class="w-4 h-4"
          />
          <div
            v-else-if="item.svg_icon"
            class="w-4 h-4"
            v-html="item.svg_icon"
          />
          {{ item.name }}
        </AppLink>
      </div>
    </div>

    <Transition name="dropdown">
      <div
        v-if="isDropDownOpen"
        class="menu__dropdown-wrapper"
      >
        <div class="menu__dropdown">
          <AppLink
            v-for="child in hoveredItem?.children"
            :key="child.slug"
            :to="makeUrlRelative(child.url)"
            class="flex items-center gap-2 break-inside-avoid pb-2"
          >
            <img
              v-if="child.svg_url"
              :alt="child.name"
              :src="child.svg_url"
              class="w-4 h-4"
            />
            <div
              v-else-if="child.svg_icon"
              class="w-4 h-4"
              v-html="child.svg_icon"
            />
            {{ child.name }}
          </AppLink>
        </div>
      </div>
    </Transition>
  </nav>
</template>

<style lang="scss" scoped>
.menu-wrapper {
  position: relative;
  width: 100%;
}

.menu {
  @apply text-xs;

  display: flex;
  justify-content: space-between;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    z-index: 3;
    display: block;
    width: 40px;
    height: calc(100% - 1px);
    background: linear-gradient(to right, $white, rgba($white, 0));
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
    transform: rotate(180deg);
  }

  &__item {
    @apply flex items-center gap-2 py-3 px-5 flex-shrink-0;
    @apply border-b-1 border-transparent;
    @apply hover:border-b-1 hover:border-hell-black-20;
  }

  &__dropdown-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    background-color: $white;
    box-shadow: 0 8px 12px rgba($black, 0.08);
  }

  &__dropdown {
    @apply text-base;

    row-gap: 8px;
    max-width: $site-width;
    height: fit-content;
    margin: 0 auto;
    padding: 24px;
    column-count: 4;
    column-gap: 24px;
  }
}

.dropdown-enter-active,
.dropdown-leave-active {
  transition: opacity 0.3s;
}

.dropdown-enter-from,
.dropdown-leave-to {
  opacity: 0;
}
</style>
